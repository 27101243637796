import loadable from "@loadable/component";

const AElement = loadable(() => import(`./AElement`));
const Input = loadable(() => import(`./Input`));
const Modal = loadable(() => import(`./Modal`));

export { AElement, Input, Modal };

export default {
	AElement,
	Input,
	Modal,
};
