import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { AElement } from "../../Elements";
import {
	NavigationHeader,
	NavigationFooter,
	NavigationHeaderGeneric,
} from "@website-builder/ui/shared";

export const UserContext = createContext();

const HeaderFooter = ({ header, footer, children, context }) => {
	const { apiBaseUrl, globalHostName, course_slug, isStaticBuild } = context;
	const [userDetails, setUserDetails] = useState({});

	return (
		<UserContext.Provider value={userDetails}>
			<div>
				{!!header ? (
					header?.isGenericHeader ? (
						<NavigationHeaderGeneric
							{...header}
							globalHostName={globalHostName}
							apiBaseUrl={apiBaseUrl}
							courseSlug={course_slug}
							isStaticBuild={isStaticBuild}
							setUserDetailsProp={(userDetails) => setUserDetails(userDetails)}
							context={context}
						/>
					) : (
						<NavigationHeader
							{...header}
							globalHostName={globalHostName}
							apiBaseUrl={apiBaseUrl}
							courseSlug={course_slug}
							isStaticBuild={isStaticBuild}
							setUserDetailsProp={(userDetails) => setUserDetails(userDetails)}
							context={context}
						/>
					)
				) : null}
				{children}
				{!!footer ? <NavigationFooter {...footer} context={context} /> : null}
			</div>
		</UserContext.Provider>
	);
};

HeaderFooter.propTypes = {
	header: PropTypes.object,
	footer: PropTypes.object,
	children: PropTypes.array,
	context: PropTypes.shape({
		fullSlug: PropTypes.string,
		workshop_id: PropTypes.number,
		typeformLink: PropTypes.string,
		apiBaseUrl: PropTypes.string,
		globalHostName: PropTypes.string,
		isStaticBuild: PropTypes.bool,
	}),
};

HeaderFooter.defaultProps = {
	header: null,
	footer: null,
	children: [],
	context: {
		fullSlug: "",
		workshop_id: 0,
		typeformLink: "",
		apiBaseUrl: "",
		globalHostName: "",
		isStaticBuild: true,
	},
	externalDependency: {
		Link: AElement,
	},
};
export default HeaderFooter;
